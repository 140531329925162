import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { nonNullable } from '@graphcommerce/next-ui'
import { useEffect } from 'react'
import { RetailStoreFragment } from '../RetailStore.gql'

/**
 * Hook to fetch and manage marker data for stores.
 *
 * @param stores - An array of stores.
 * @param isMobile - Indicates whether the app is running on a mobile device.
 * @param selected - The ID of the selected store.
 * @param map - The Google Maps map on which the markers will be displayed.
 * @param isStorePage - Indicates whether the hook is used on a page for individual stores.
 * @param urlPath - Optional array of paths in the URL.
 */
export function useStoreMarkers(
  stores: RetailStoreFragment[],
  isMobile: boolean,
  selected: string | undefined,
  map: google.maps.Map | null = null,
  isStorePage = false,
  urlPath?: string[],
) {
  useEffect(() => {
    if (!stores || !map) return

    const renderer = {
      render: ({ position }) => {
        const icon = document.createElement('img')
        icon.src = '/images/da-logo.svg'
        icon.width = 35
        icon.height = 35

        return new google.maps.marker.AdvancedMarkerElement({
          position,
          content: icon,
        })
      },
    }

    const markers = stores?.map((store) => {
      if (!store) return null

      const { path, latitude, longitude, name, relation_nr } = store

      const icon = document.createElement('img')
      icon.src = '/images/da-logo.svg'
      icon.width = 25
      icon.height = 25

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: latitude ?? 55, lng: longitude ?? 55 },
        content: icon,
        map,
        title: name,
      })

      const isOpen = !isStorePage ? selected === path : urlPath?.[1] === path

      if (!isOpen) return marker

      map.setZoom(11)

      const newPosition: google.maps.LatLngLiteral = {
        lat: Number(latitude),
        lng: Number(longitude),
      }
      map.setCenter(newPosition)

      if (relation_nr && isOpen) {
        const element = document.getElementById(relation_nr)
        element?.parentElement?.scrollIntoView({
          behavior: 'smooth',
          block: !isMobile ? 'start' : 'end',
        })
      }

      return marker
    })

    const clusterer = new MarkerClusterer({ markers: markers.filter(nonNullable), map, renderer })
  }, [stores, map, selected, isMobile, isStorePage, urlPath])
}
